<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="'查看'"
    :show-footer="false"
    @openedCallback="openedCallback"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
    >
      <UploadImg slot="photoIds" slot-scope="{item}" :limit="3" :upload-lists="photoUploadLists" :pic-disable="true" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="true" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
export default {
  name: 'Form',
  components: { UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      formId: '',
      dict: {
        volunteerTaskType: []
      },
      photoUploadLists: [],
      formModel: {
        category: '',
        title: '',
        photoIds: '',
        intro: '',
        tenant: [],
        address: '',
        contactPerson: '',
        contactPhone: '',
        credits: 0,
        claimantsQuantity: 0,
        claimantsTime: [],
        claimantsStartTime: '',
        claimantsEndTime: '',
        time: [],
        startTime: '',
        endTime: '',
        description: '',
        belongs: 0,
        checkInType: 0,
        cancellable: 0
      }
    }
  },
  computed: {
    formItem() {
      return {
        'category': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '分类',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.volunteerTaskType,
          'disabled': true
        },
        'title': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '标题',
          'disabled': true
        },
        'photoIds': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoIds'
        },
        'intro': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '简介',
          'disabled': true
        },
        'tenant': {
          'elColSpan': 12,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '地点',
          'disabled': true
        },
        'contactPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系人',
          'disabled': true
        },
        'contactPhone': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'disabled': true
        },
        'credits': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '奖励积分',
          'disabled': true
        },
        'claimantsQuantity': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '总报名人数',
          'disabled': true
        },
        'claimantsTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '报名时间',
          'type': 'datetimerange',
          'valueFormat': 'yyyy-MM-dd HH:mm:ss',
          'disabled': true
        },
        'time': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '任务时间',
          'type': 'datetimerange',
          'valueFormat': 'yyyy-MM-dd HH:mm:ss',
          'disabled': true
        },
        'belongs': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '参与者类型',
          'data': ['志愿者', '党员', '用户'],
          'disabled': true
        },
        'checkInType': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '签到类型',
          'data': ['活动前', '活动前后'],
          'disabled': true
        },
        'description': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '任务描述',
          'disabled': true
        }
      }
    }
  },
  methods: {
    ...mapActions(['downloadFile', 'getSysDictServe', 'pubTaskPublicDetail']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.dict[typeCode] = res.data
      })
    },
    handleDetail(id) {
      Object.assign(this, this.$options.data.call(this))
      this.getSysDictList('volunteerTaskType')
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        this.pubTaskPublicDetail({ id: this.formId }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            reject()
            return
          }

          if (res.data.photoIds && res.data.photos) {
            const photoIds = res.data.photoIds.split(',')
            const photos = res.data.photos.split(',')
            photoIds.forEach((item, index) => {
              if (index in photos) {
                this.photoUploadLists.push({
                  'id': item,
                  'url': photos[index]
                })
              }
            })
          }

          this.formModel.id = this.formId
          this.formModel.category = res.data.category
          this.formModel.title = res.data.title
          this.formModel.photoIds = res.data.photoIds
          this.formModel.intro = res.data.intro
          this.formModel.tenant = res.data.tenant.split(',').slice(2)
          this.formModel.address = res.data.address
          this.formModel.contactPerson = res.data.contactPerson
          this.formModel.contactPhone = res.data.contactPhone
          this.formModel.credits = res.data.credits
          this.formModel.claimantsQuantity = res.data.claimantsQuantity
          this.formModel.claimantsTime = [res.data.claimantsStartTime, res.data.claimantsEndTime]
          this.formModel.time = [res.data.startTime, res.data.endTime]
          this.formModel.description = res.data.description
          this.formModel.belongs = res.data.belongs
          this.formModel.checkInType = res.data.checkInType
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    getFileDetail(ids) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.photoUploadLists.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
